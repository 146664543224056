import React from "react"
import PropTypes from "prop-types"
import { Link, useStaticQuery, graphql } from "gatsby"

import { Container, Nav, Navbar } from "react-bootstrap"
// import Img from "gatsby-image"

const Header = ({ links, title }) => {
  const data = {}
  // const data = useStaticQuery(graphql`
  //   {
  //     navbarBrandImage: file(relativePath: { eq: "favicon.png" }) {
  //       childImageSharp {
  //         fixed(width: 38, height: 32) {
  //           ...GatsbyImageSharpFixed_noBase64
  //         }
  //       }
  //     }
  //   }
  // `)

  return (
    <header>
      {/* <JumbotronHeader description={description} title={title} /> */}
    </header>
  )
}

Header.propTypes = {
  links: PropTypes.array,
  title: PropTypes.string,
}

Header.defaultProps = {
  links: [],
  title: ``,
}

export default Header
