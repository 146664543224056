import React from "react";
import { active, finish, step } from "./StepStatus.module.scss"

const StepStatus = ({ values, totalSteps }) => {

    //destructuring the object from values
  // const { step } = values;
  const currentStep = values.step;

  return (
    <>
      {/* <!-- Circles which indicates the steps of the form: --> */}
      <div className="text-center py-3">
        {[...Array(totalSteps)].map((e, idx) => {
          idx++
          if (idx === currentStep) {
            return (
              <span key={idx} className={`${step} ${active}`}></span>
            )
          }
          if (idx > currentStep) {
            return (
              <span key={idx} className={`${step}`}></span>
            )
          }

          if (idx < currentStep) {
            return (
              <span key={idx} className={`${step} ${finish}`}></span>
            )
          }
        })}
      </div>
    </>
  );
};

export default StepStatus;
