import React from "react"
import PropTypes from "prop-types"
import { Link, useStaticQuery, graphql } from "gatsby"

import { Container, Nav, Navbar } from "react-bootstrap"
// import Img from "gatsby-image"

const Navg = ({ links, title }) => {
  const data = {}
  // const data = useStaticQuery(graphql`
  //   {
  //     navbarBrandImage: file(relativePath: { eq: "favicon.png" }) {
  //       childImageSharp {
  //         fixed(width: 38, height: 32) {
  //           ...GatsbyImageSharpFixed_noBase64
  //         }
  //       }
  //     }
  //   }
  // `)

  return (
    <nav className="sticky-top">
      <Navbar
        id="nav"
        bg="light"
        collapseOnSelect
        expand="md"
        variant="light"
      >
        <Container>
          <Navbar.Brand as={Link} to="/">
            {/* <Img
              fixed={data.navbarBrandImage.childImageSharp.fixed}
              loading="auto"
              draggable={false}
              className="d-inline-block align-top"
              alt="Promptfu Logo"
            /> */}
            {title}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto">
              {links.map(link => (
                <Nav.Link
                  as={Link}
                  to={link.link}
                  title={link.title}
                  activeClassName="active"
                  key={link.link}
                >
                  {link.name}
                </Nav.Link>
              ))}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </nav>
  )
}

Navg.propTypes = {
  links: PropTypes.array,
  title: PropTypes.string,
}

Navg.defaultProps = {
  links: [],
  title: ``,
}

export default Navg
