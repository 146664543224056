import React from "react";
import PropTypes from "prop-types"
import { Container, Row, Col } from "react-bootstrap";
import { useState } from "react";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepFinal from "./StepFinal";
import StepStatus from "./StepStatus";

import { submitContactFormData } from "../Firebase";

class ContactForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      step: 1,
      fullName: "",
      email: "",
      eventDate: "",
      eventInfo: "",
      eventVenue: "",
      package: "",
      phoneNumber: "",
      cc: "info@weddingtails.com",
      to: "",
      template: {
        name: "helloFromWeddingTails",
        data: {
          fullName: "",
          phoneNumber: "",
          eventDate: "",
          eventInfo: "",
          eventVenue: "",
          package: "",
          fullName: "",
          eventInfo: "",
        }
      }
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleNextStep = this.handleNextStep.bind(this)
    this.handlePrevStep = this.handlePrevStep.bind(this)
    this.handlePhoneNumberChange = this.handlePhoneNumberChange.bind(this)
    this.formatPhoneNumber = this.formatPhoneNumber.bind(this)
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value,
    })
  }

  handleSubmit = event => {
    event.preventDefault()
    submitContactFormData({
      fullName: this.state.fullName,
      email: this.state.email,
      eventDate: this.state.eventDate,
      eventInfo: this.state.eventInfo,
      eventVenue: this.state.eventVenue,
      package: this.state.package,
      phoneNumber: this.state.phoneNumber,
      cc: this.state.cc,
      to: this.state.email,
      template: {
        name: "helloFromWeddingTails",
        data: {
          fullName: this.state.fullName,
          phoneNumber: this.state.phoneNumber,
          eventDate: this.state.eventDate,
          eventInfo: this.state.eventInfo,
          eventVenue: this.state.eventVenue,
          package: this.state.package,
        },
      },
    })
  }
  
  handlePrevStep(prevState) {
    this.setState(prevState => {
      return {step: prevState.step - 1}
    })
  }

  handleNextStep(prevState) {
    this.setState(prevState => {
      return {step: prevState.step + 1}
    })
  }

  handlePhoneNumberChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name

    const formattedPhoneNumber = this.formatPhoneNumber(value);

    this.setState({
      [name]: formattedPhoneNumber,
    })
  }

  formatPhoneNumber = value => {
    // if input value is falsy eg if the user deletes the input, then just return
    if (!value) return value;
  
    // clean the input for any non-digit values.
    const phoneNumber = value.replace(/[^\d]/g, '');
  
    // phoneNumberLength is used to know when to apply our formatting for the phone number
    const phoneNumberLength = phoneNumber.length;
  
    // we need to return the value with no formatting if its less then four digits
    // this is to avoid weird behavior that occurs if you  format the area code to early
  
    if (phoneNumberLength < 4) return phoneNumber;
  
    // if phoneNumberLength is greater than 4 and less the 7 we start to return
    // the formatted number
    if (phoneNumberLength < 7) {
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
    }
  
    // finally, if the phoneNumberLength is greater then seven, we add the last
    // bit of formatting and return it.
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  }

  render() {
    switch (this.state.step) {
      case 1:
        return (
          <>
            <StepOne nextStep={this.handleNextStep} handleFormData={this.handleInputChange}
              handlePhoneNumberFormData={this.handlePhoneNumberChange} values={this.state} />
            <StepStatus active={this.state.step} totalSteps={2} values={this.state}/>
          </>
        )
      case 2:
        return (
          <>
            <StepTwo nextStep={this.handleNextStep} prevStep={this.handlePrevStep} handleFormData={this.handleInputChange} handleSubmitFormData={this.handleSubmit} values={this.state} />
            <StepStatus active={this.state.step} totalSteps={2} values={this.state}/>
          </>
        )
      case 3:
        return (
          <>
            <StepFinal values={this.state}/>
            <StepStatus active={this.state.step} totalSteps={2} values={this.state}/>
          </>
        )
      default:
        return(
          <>
            <StepOne nextStep={this.handleNextStep} handle values={this.state} />
            <StepStatus active={this.state.step} totalSteps={2} values={this.state}/>
          </>
        )
    }
  }
}

export default ContactForm
