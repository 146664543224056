import { initializeApp } from "firebase/app"
// import { getAnalytics } from "firebase/analytics"
import { addDoc, collection ,getFirestore, Timestamp } from "firebase/firestore"

const firebaseConfig = {
  apiKey: process.env.GATSBY_APIKEY,
  authDomain: process.env.GATSBY_AUTHDOMAIN,
  projectId: process.env.GATSBY_PROJECTID,
  storageBucket: process.env.GATSBY_STORAGEBUCKET,
  messagingSenderId: process.env.GATSBY_MESSAGINGSENDERID,
  appId: process.env.GATSBY_APPID,
  measurementId: process.env.GATSBY_MEASUREMENTID
}

const app = initializeApp(firebaseConfig)
const db = getFirestore(app)

const submitContactFormData = async (data) => {
  try {
    console.log(data)
    data['created'] = Timestamp.now()
    await addDoc(collection(db, process.env.GATSBY_FIRESTORE_CONTACT_FORM_COLLECTION), data)
  } catch (err) {
    console.log(err)
    alert(err.message)
  }
}

export { submitContactFormData }