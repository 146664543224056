import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { Parallax } from 'react-parallax'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { getImage, getSrc, GatsbyImage } from "gatsby-plugin-image"
import {
  FaMapMarkerAlt,
  FaEnvelope
} from "react-icons/fa"

import Layout from "components/layout"
import ContactForm from "components/ContactForm"
// import SEO from "components/seo"

class IndexPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}

    // bind function(s) in constructor instead of render
  }

  render() {
    const description = this.props.data.site.siteMetadata.description
    const title = this.props.data.site.siteMetadata.title
    const image1 = getImage(this.props.data.GoldenRetriever.localImage)
    const image2 = getImage(this.props.data.PugWedding.localImage)
    const image3 = getImage(this.props.data.BronzeFluff.localImage)
    const image4 = getImage(this.props.data.Tails.localImage)
    const image5 = getImage(this.props.data.PuppyWaggingTail.localImage)
     const insideStyles = {
      //  background: "white",
      background: "lightsteelblue radial-gradient(lightskyblue, transparent)",
       padding: 20,
       position: "absolute",
       top: "60%",
       left: "50%",
       transform: "translate(-50%,-50%)",
       maxWidth: "500px",
       width: "90%",
       borderRadius: "25px",
     };

    return (
      <Layout>
        {/* <SEO /> */}
        <Container className="px-0 py-0" fluid>
          <Row>
            <Col>
              {/* <Parallax
                strength={-200}
                blur={{ min: -15, max: 15 }}
              >
                <Background>
                  <GatsbyImage image={image1} alt="testing" style={{ height: "100vh", width: "100vw" }} />
                </Background>
                <div style={{ minHeight: "80vh" }}>
                  <div style={insideStyles} className="text-center">
                    <h1 className="display-1">Wedding Tails</h1>
                    <h3>Wedding Pet Attendants</h3>
                  </div>
                </div>
              </Parallax> */}
              <Parallax
                blur={{ min: -15, max: 10 }}
                bgImage={getSrc(image1)}
                bgImageAlt="golden"
                strength={-100}
                className="bg-light"
              >
                <div style={{ minHeight: "80vh" }}>
                  <div style={insideStyles} className="text-center">
                    <GatsbyImage image={image4} alt="tails" imgStyle={{ borderRadius: "25px" }} />
                    <h1 className="display-1" style={{ color: "whitesmoke" }}>Wedding Tails</h1>
                    <h3 style={{ color: "whitesmoke" }}>Wedding Pet Attendants</h3>
                  </div>
                </div>
              </Parallax>
            </Col>
          </Row>
        </Container>
        <Container className="my-2" fluid>
          <Row>
            <Col>
              <svg height="5vh" width="100%">
                <line x1="50%" y1="0" x2="50%" y2="100%" style={{ stroke: "black", strokeWidth: 1 }}/>
              </svg>
            </Col>
          </Row>
        </Container>
        <Container fluid className="text-center">
          <Row>
            <Col md={8} lg={6} className="mx-auto">
              <h1 id="about" className="anchor">About Us</h1>
              <h3><p className="lead">Your furry friend deserves a plus one and <u>you</u> deserve
              the peace of mind that your furry family members will be well taken care of.</p></h3>
            </Col>
          </Row>
        </Container>
        <Container className="my-2" fluid>
          <Row>
            <Col>
              <svg height="5vh" width="100%">
                <line x1="50%" y1="0" x2="50%" y2="100%" style={{ stroke: "black", strokeWidth: 1 }}/>
              </svg>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col className="text-center">
              <h1 id='services' className="anchor">What We Do</h1>
              <h5 className="text-muted">Our Packages Start At $295!</h5>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} lg={4} className="d-flex align-items-stretch">
              <Card border="light" className="m-3">
                <Card.Img as={GatsbyImage} variant="top" image={image1} alt="gold-card-img" style={{ height: '30vh', objectFit: 'cover' }}/>
                <Card.Body>
                  <Card.Title>Gold(en)</Card.Title>
                  <Card.Text className="text-muted">
                    Full day of coverage up to 6 hours.
                  </Card.Text>
                  <Card.Text>
                    Enjoy your pup getting ready with you, having them invovled
                    in the ceremony, and let them hang around your other loved ones during cocktail hour.
                  </Card.Text>
                </Card.Body>
                {/* <Card.Footer>
                  <small className="text-muted">Last updated 3 mins ago</small>
                </Card.Footer> */}
              </Card>
            </Col>
            <Col xs={12} sm={12} lg={4} className="d-flex align-items-stretch">
              <Card border="light" className="m-3">
              <Card.Img as={GatsbyImage} variant="top" image={image2} alt="silver-card-img" style={{ height: '30vh', objectFit: 'cover' }}/>
                <Card.Body>
                  <Card.Title>Silver Pug</Card.Title>
                  <Card.Text className="text-muted">
                    Up to 4 hours of coverage.
                  </Card.Text>
                  <Card.Text>
                    This package is perfect for the couple who wants more involvement from
                    their pup in the wedding. We will assist with guiding your pet during
                    the ceremony and work with the other vendors to incorporate your four-legged
                    friend accordingly.
                  </Card.Text>
                </Card.Body>
                {/* <Card.Footer>
                  <small className="text-muted">Last updated 3 mins ago</small>
                </Card.Footer> */}
              </Card>
            </Col>
            <Col xs={12} sm={12} lg={4} className="d-flex align-items-stretch">
              <Card border="light" className="m-3">
              <Card.Img as={GatsbyImage} variant="top" image={image3} alt="bronze-card-img" style={{ height: '30vh', objectFit: 'cover' }}/>
                <Card.Body>
                  <Card.Title>Bronze Fluff</Card.Title>
                  <Card.Text className="text-muted">
                    Up to 2 hours of coverage.
                  </Card.Text>
                  <Card.Text>
                    This package is perfect for the couple who is looking to include their furry
                    loved one in just the pre-wedding or post-ceremony photos.
                  </Card.Text>
                </Card.Body>
                {/* <Card.Footer>
                  <small className="text-muted">Last updated 3 mins ago</small>
                </Card.Footer> */}
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="text-center">
              <h4 className="text-muted"><u>All Packages Include:</u></h4>
              <ul className="px-2" style={{ listStyleType: 'none' }}>
                <li>Consultation Meeting</li>
                <li>One Pet Attendant</li>
                <li>One Pet</li>
                <li>Assistance with photography</li>
                <li>Planning with vendors</li>
                <li>Pick up and drop off transportation of pet</li>
                <li>Up to 50 miles of travel costs<sup>1</sup></li>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col className="text-center">
              <h4 className="text-muted"><u>Additional Items Available:</u></h4>
              <ul className="px-2" style={{ listStyleType: 'none' }}>
                <li>Extra Pet Attendant</li>
                <li>Extra Pet</li>
                <li>Rehearsal Dinner</li>
                <li>Engagement Photos</li>
                <li>Proposal Photos</li>
                <li>Graduation Photos</li>
                <li>Family Photos</li>
                <li>Honeymoon Boarding</li>
              </ul>
            </Col>
          </Row>
        </Container>
        <Container className="my-2" fluid>
          <Row>
            <Col>
              <svg height="5vh" width="100%">
                <line x1="50%" y1="0" x2="50%" y2="100%" style={{ stroke: "black", strokeWidth: 1 }}/>
              </svg>
            </Col>
          </Row>
        </Container>
        <Container fluid>
          <Row>
            <Col md={8} lg={6} className="mx-auto text-center">
              <h3><p className="lead">Your dream day would <u>not</u> be the same without your fur babies...</p></h3>
              <GatsbyImage image={image5} alt="puppy-wagging-tail" />
              <h3><p className="lead">We are here to help.</p></h3>
            </Col>
          </Row>
        </Container>
        <Container className="my-2" fluid>
          <Row>
            <Col>
              <svg height="5vh" width="100%">
                <line x1="50%" y1="0" x2="50%" y2="100%" style={{ stroke: "black", strokeWidth: 1 }}/>
              </svg>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col className="text-center">
              <h1 id="contact" className="anchor">Contact Us</h1>
              <p>Submit your inquery below and we will schedule a consultation and answer any of your questions.</p>
              <p>We would <u>love</u> the opportunity to take care of your fur baby on your very special day.</p>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={{ span: 4, order: 2 }} lg={{ span: 4, order: 2 }} xl={{ span: 3, order: 2 }} style={{display: 'flex', justifyContent: 'center'}}>
              <div>
              <p style={{ marginBottom: '0' }}><span><FaMapMarkerAlt />&nbsp;</span><u>Servicing:</u></p>
              <ul>
                <li>Chicago, IL and surrounding suburbs</li>
                <li>Wisconsin</li>
                <li>Indiana</li>
              </ul>
              <p><FaEnvelope />&nbsp;info@weddingtails.com</p>
              </div>
            </Col>
            <Col md={{ span: 8, order: 1 }} lg={{ span: 8, order: 1}} xl={{ span: 6, order: 1 }}>
              <ContactForm />
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="text-muted" style={{ fontSize: '0.6rem' }}><sup>1</sup>Time of coverage begins once we pick up your dog and head to the venue. Time ends once we drop your dog back at your house/boarding location. Transportation includes milage from our location and round trip back to our location.</p>
            </Col>
          </Row>
        </Container>
      </Layout>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            description
            image
            title
          }
        },
        GoldenRetriever: dropboxImagesYaml(name: {eq: "golden-retriever-bowtie"}) {
          id
          localImage {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        },
        BronzeFluff: dropboxImagesYaml(name: {eq: "bronze-fluff"}) {
          id
          localImage {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        },
        PugWedding: dropboxImagesYaml(name: {eq: "pug-wedding"}) {
          id
          localImage {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        },
        FluffInTie: dropboxImagesYaml(name: {eq: "fluff-in-tie"}) {
          id
          localImage {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        },
        Tails: dropboxImagesYaml(name: {eq: "tails"}) {
          id
          localImage {
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG)
            }
          }
        },
        PuppyWaggingTail: dropboxImagesYaml(name: {eq: "puppy-wagging-tail"}) {
          id
          localImage {
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG, width: 200)
            }
          }
        }
      },
    `}
    render={data => <IndexPage data={data} {...props} />}
  />
)
