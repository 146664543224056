import React from "react";
import { Card } from "react-bootstrap";

const StepFinal = ({ values }) => {

    //destructuring the object from values
  const { fullName, email } = values;
  return (
    <>
      <Card style={{ border: 'none' }} className="text-center">
        <Card.Body>
          <Card.Text>
            Your form has been submitted.
          </Card.Text>
          <Card.Text as={'h1'}>
            Thank you for contacting us!
          </Card.Text>
        </Card.Body>
      </Card>
    </>
  );
};

export default StepFinal;
