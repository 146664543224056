import React from "react";
import { Button, Card, Form } from "react-bootstrap";

import { actionButtonPrevious, msform } from "./ContactForm.module.scss"

// creating functional component ans getting props from app.js and destucturing them
const StepTwo = ({ nextStep, handleFormData, handleSubmitFormData, prevStep, values }) => {

    // after form submit validating the form data using validator
  const handleSubmit = (event) => {
    event.preventDefault();
    handleSubmitFormData(event);
    nextStep();
  };

  const radios = [
    {name: "package", value: "Gold"},
    {name: "package", value: "Silver"},
    {name: "package", value: "Bronze"}
  ]
  return (
    <Card style={{ border: 'none' }}>
      <Card.Body>
        <Card.Title className="text-center">About Your Event</Card.Title>
        <Card.Subtitle className="text-center text-muted">Tell us about your event</Card.Subtitle>
        <Form className={msform} onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formBasicEventVenue">
            <Form.Label>Event Venue:<sup>*</sup></Form.Label>
            <Form.Control type="text" name="eventVenue" placeholder="Event Venue:" value={values.eventVenue} onChange={handleFormData} required/>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEventDate">
            <Form.Label>EVENT DATE:<sup>*</sup></Form.Label>
            <Form.Control type="date" name="eventDate" value={values.eventDate} onChange={handleFormData} required/>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPackageInterested">
            <Form.Label>WHICH PACKAGE INTERESTS YOU?<sup>*</sup></Form.Label>
              {radios.map((radio, idx) => (
                <Form.Check
                  key={idx}
                  type="radio"
                  label={radio.value}
                  name={radio.name}
                  value={radio.value}
                  id={`radio-${idx}`}
                  onChange={handleFormData}
                  checked={radio.value == values.package}
                  required
                />
              ))}
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEventInfo">
            <Form.Label>TELL US ABOUT YOUR EVENT AND YOUR VISION!<sup>*</sup></Form.Label>
            <Form.Control as="textarea"
              style={{ whiteSpace: 'pre-line' }}
              name="eventInfo"
              value={values.eventInfo}
              placeholder="Dare to dream, then decide to do.&#13;&#10;--Annette White"
              rows="5"
              onChange={handleFormData}
              required
            />
            <Form.Text className="text-muted" style={{ textAlign: 'left' }}>
              Some example details to provide are:
              <ul>
                <li>Where is the venue located?</li>
                <li>Where will your pet(s) be picked up and dropped off?</li>
                <li>How would you like to incorporate your pet(s)?</li>
                <li>Any specific wardrobe or picture requests?</li>
                <li>Any specific treats (or treats to avoid)?</li>
                <li>Any pet mobility issues?</li>
              </ul>
            </Form.Text>
          </Form.Group>
          <div className="text-center">
            <Button className={actionButtonPrevious} type="submit" onClick={prevStep}>Previous</Button>
            <Button type="submit">Submit</Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default StepTwo;
