import React from "react";
import { Button, Card, Form } from "react-bootstrap";

import { msform } from "./ContactForm.module.scss"

// creating functional component ans getting props from app.js and destucturing them
const StepOne = ({ nextStep, handleFormData, handlePhoneNumberFormData, values }) => {

  const handleSubmit = (event) => {
    event.preventDefault();
    nextStep()
  };

  return (
    <Card style={{ border: 'none' }}>
      <Card.Body>
        <Card.Title className="text-center">About You</Card.Title>
        <Card.Subtitle className="text-center text-muted">Tell us about you</Card.Subtitle>
        <Form className={msform} onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formBasicFullName">
            <Form.Label>FULL NAME:<sup>*</sup></Form.Label>
            <Form.Control type="text" name="fullName" placeholder="Full Name:" value={values.fullName} onChange={handleFormData} required/>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>EMAIL:<sup>*</sup></Form.Label>
            <Form.Control type="email" name="email" placeholder="Email:" value={values.email} onChange={handleFormData} required/>
            <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPhoneNumber">
            <Form.Label>PHONE NUMBER:</Form.Label>
            <Form.Control type="tel" name="phoneNumber" placeholder="Phone Number:" pattern="^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$" value={values.phoneNumber} onChange={handlePhoneNumberFormData}/>
            <Form.Text className="text-muted">
              We'll never share your phone number with anyone else.
            </Form.Text>
            {/* <Form.Text className="text-muted">
              Format: 123-456-7890
            </Form.Text> */}
          </Form.Group>
          <div className="text-center">
            <Button variant="primary" type="submit">Next</Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default StepOne;
